import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { Form, Switch } from 'ember-primitives';
const BOOLEAN = [
    'display-as-list',
    'hide-done',
    'external'
];
export class DisplaySettings extends Component {
    static{
        template(`
    <Form @onChange={{this.update}}>
      <Switch @checked={{this.qps.displayAsList}} as |s|>
        <s.Control name="display-as-list" />
        <s.Label>List instead of boxes</s.Label>
      </Switch>

      <Switch @checked={{this.qps.hideDone}} as |s|>
        <s.Control name="hide-done" />
        <s.Label>Hide done</s.Label>
      </Switch>

      <Switch @checked={{this.qps.external}} as |s|>
        <s.Control name="external" />
        <s.Label>Show external</s.Label>
      </Switch>

      <label>
        <span>Include Text</span>
        <input name="with" value={{this.qps.with}} />
      </label>

      <label>
        <span>Exclude Text</span>
        <input name="without" value={{this.qps.without}} />
      </label>

      <label>
        <span>Exclude Labels</span>
        <select name="exclude-labels">
          <option value="">Show All / Exclude None</option>
          {{#each this.data.labels as |label|}}
            <option value={{label}}>{{label}}</option>
          {{/each}}
        </select>
      </label>
    </Form>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
    @service
    qps;
    @service
    data;
    update = (newValues1)=>{
        let qps1 = this.qps.current;
        for (let boolKey1 of BOOLEAN){
            if (!(boolKey1 in newValues1)) {
                newValues1[boolKey1] = 'off';
            }
        }
        for (let [key1, value1] of Object.entries(newValues1)){
            if (qps1[key1] !== value1) {
                switch(true){
                    case BOOLEAN.includes(key1):
                        qps1[key1] = value1 === 'on' ? '1' : '0';
                        break;
                    default:
                        qps1[key1] = value1;
                        break;
                }
            }
        }
        this.qps.set(qps1);
    };
}
export const Filters = template(`
  <details>
    <summary>Filters and Display</summary>
    <DisplaySettings />
  </details>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
