
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("is-ready/app", function(){ return i("is-ready/app.js");});
d("is-ready/config/environment", function(){ return i("is-ready/config/environment.js");});
d("is-ready/router", function(){ return i("is-ready/router.js");});
d("is-ready/services/data", function(){ return i("is-ready/services/data.js");});
d("is-ready/services/qps", function(){ return i("is-ready/services/qps.js");});
d("is-ready/services/page-title", function(){ return i("is-ready/services/page-title.js");});
d("is-ready/component-managers/glimmer", function(){ return i("is-ready/component-managers/glimmer.js");});
d("is-ready/container-debug-adapter", function(){ return i("is-ready/container-debug-adapter.js");});
d("is-ready/services/-ensure-registered", function(){ return i("is-ready/services/-ensure-registered.js");});
d("is-ready/templates/application", function(){ return i("is-ready/templates/application.js");});
d("is-ready/routes/application", function(){ return i("is-ready/routes/application.js");});
d("is-ready/routes/index", function(){ return i("is-ready/routes/index.js");});
d("is-ready/templates/-components/filters", function(){ return i("is-ready/templates/-components/filters.js");});
d("is-ready/templates/-components/footer", function(){ return i("is-ready/templates/-components/footer.js");});
d("is-ready/templates/-components/header", function(){ return i("is-ready/templates/-components/header.js");});
d("is-ready/templates/-components/section", function(){ return i("is-ready/templates/-components/section.js");});
d("is-ready/templates/-components/utils", function(){ return i("is-ready/templates/-components/utils.js");});
d("is-ready/templates/issues", function(){ return i("is-ready/templates/issues.js");});





if (!runningTests) {
  i("../app").default.create({});
}

